import "./App.css";
import readXlsxFile from "read-excel-file";
import * as xlsx from "xlsx";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { read, utils, writeFileXLSX } from "xlsx";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Shipway = () => {
  const [first, setfirst] = useState([]);
  const [sec, setSec] = useState([]);
  const [finalResult, setFinalResult] = useState([]);
  const navigate = useNavigate();
  const handleChangeIthink = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array", cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setfirst(json);
        console.log("Ithink", json);
        const unique = [...new Set(json.map((item) => item.status))];
        console.log("IthinkUnique", unique);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  const handleChangeRTO = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array", cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setSec(json);
        console.log("RTO", json);
        const unique = [...new Set(json.map((item) => item.status))];
        console.log("RTOUnique", unique);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleCheckData = () => {
    const firstTopData = first.filter(
      (dat) => dat["Status"] == "RTO Delivered"
    );
    const dataBase = firstTopData.filter((item) => {
      console.log("item[]", item["Order Id"]);
      const numericStr = String(item["Order Id"]).replace(/[^0-9]/g, "");
      // Convert the string to a number
      const firstItem = Number(numericStr);
      return sec.every((ele) => {
        const numericStr = String(ele.order_id).replace(/[^0-9]/g, "");
        // Convert the string to a number
        const secondItem = Number(numericStr);
        return firstItem !== secondItem;
        // return !item.order.toString().includes(ele.order_id.toString());
      });
    });
    console.log("dataBasedataBase", dataBase);
    setFinalResult(dataBase);
    console.log("dataBasedataBase", dataBase);
  };
  const handleDownloadFile = () => {
    const ws = utils.json_to_sheet(finalResult);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "PendingRTO.xlsx");
  };
  return (
    <>
      <Layout>
        <div className="flex">
          <div className="title-bar">Ithink Logistics</div>
          <button
            className="title-bar2"
            onClick={() => window.open("/", "_blank")}
          >
            Go to Shipway
          </button>
        </div>
        <div className="excel-boxes-outer">
          <div className="excel-boxes">
            <div className="title-box">Ithink File Upload</div>
            <input
              type="file"
              name="upload"
              id="upload"
              onChange={handleChangeIthink}
            />
          </div>
          <div className="excel-boxes">
            <div className="title-box">RTO Delivered File Upload</div>
            <input
              type="file"
              name="upload"
              id="upload"
              onChange={handleChangeRTO}
            />
          </div>
        </div>
        <div className="flex" style={{ marginTop: "20px" }}>
          <button
            className="title-bar2"
            style={{ backgroundColor: "green" }}
            onClick={() => handleCheckData()}
          >
            Check Orders
          </button>
        </div>
        <div className="table-box">
          <div>
            <TableContainer sx={{ background: "white", borderRadius: "1rem" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell className="table-cells">Order Id</TableCell>
                    <TableCell className="table-cells">Mobile</TableCell>
                    <TableCell className="table-cells">Status</TableCell>
                    <TableCell className="table-cells">
                      Updated Status Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finalResult.map((data, index) => (
                    <TableRow
                      key={index}
                      className="table-row"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell className="table-cells">
                        {data["Order Id"]}
                      </TableCell>
                      <TableCell className="table-cells">
                        {data["Phone"]}
                      </TableCell>
                      <TableCell className="table-cells">
                        {data["Status"]}
                      </TableCell>
                      <TableCell className="table-cells">
                        {moment(new Date(data["Status Date"])).format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {finalResult?.length > 0 && (
            <div className="flex" style={{ marginTop: "20px" }}>
              <button
                className="title-bar2"
                style={{ backgroundColor: "blue" }}
                onClick={() => handleDownloadFile()}
              >
                Download Pending Orders
              </button>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Shipway;
