import React from "react";
import { Route, Routes } from "react-router-dom";
import Ithink from "./Ithink";
import Shipway from "./Shipway";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Shipway />} />
      <Route path="/ithink" element={<Ithink />} />
    </Routes>
  );
};

export default AppRoutes;
